.form-container {
  display: flex;
  flex-direction: column;
  /* background-color: #f5f5f5; */
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.top-container {
  background: linear-gradient(to bottom, #f5c842, #ffffff);
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input,
select,
textarea {
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.submit-button {
  background-color: #edb304;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  font-size: 12px;
}
